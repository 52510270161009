import Vue from "vue"
import Vuex from "vuex"


import loading from "@/store/modules/loading"
import auth from "@/store/modules/auth"
import catalogue from "@/store/modules/catalogue"
import messaging from "@/store/modules/messaging"
import revisions from "@/store/modules/revisions"
import viewcreator from "@/store/modules/viewcreator"
import grafs from "@/store/modules/grafs"
import main from "@/store/modules/main"

Vue.use(Vuex)

export default new Vuex.Store({
	strict: true,
	modules: {
		loading,
		auth,
		catalogue,
		messaging,
		revisions,
		viewcreator,
		grafs,
		main

	}
})
