import Vue from "vue"
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from "./App"
import router from "./router"
import store from "./store"

import IdleVue from 'idle-vue'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import Form from "@/core/utils/Form"
import { EventBus } from "@/core/utils/EventBus"
import Mixins from "@/core/mixins"
import Components from "@/core/components"
import Directives from "@/core/directives"

import { Socket } from '@/core/utils/Socket'
import { AppActivity } from '@/core/utils/AppActivity'
import * as Sentry from "@sentry/vue"

// Vue.use(Socket)

window.EventBus = EventBus
window.Form = Form

Vue.mixin(Mixins)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(Components)
Vue.use(Directives)

// trigger onIdle after 30 seconds of inactivity
Vue.use(IdleVue, { eventEmitter: new Vue(), idleTime: 30000, startAtIdle: false, store })

Vue.use(VueViewer, {
	defaultOptions: {
		zIndexInline: 2021,
		focus: false,
		toolbar: false,
		navbar: false
	}
})

// place after other components
Vue.use(AppActivity, { store, router })

Vue.config.productionTip = false

if (process.env.NODE_ENV === "production") {
	Sentry.init({
	  Vue,
	  dsn: process.env.VUE_APP_SENTRY_URL,
	  integrations: [
	    new Sentry.Replay(),
	    new Sentry.Feedback({
		  useSentryUser: {
		    email: "email",
		    name: "fullName",
		  },
	      colorScheme: "light",
	      themeLight: {
		      submitBackground: "#63c2de",
		      submitBackgroundHover: "#20a8d8",
		      submitBorder: "#63c2de",
	      },
	      autoInject: false,
	    }),
	  ],
	  // Session Replay
	  replaysSessionSampleRate: 0.1,
	  replaysOnErrorSampleRate: 1.0,
	  environment: process.env.NODE_ENV,
	  debug: true
	})
}

async function initializeRoutes() {
  const routes = await store.dispatch('main/fetchRoutes').then((results) => {
        return results
    })
  // Map your routes into a format Vue Router expects
  let portalRoutes = routes.filter(route => { return route.path.includes("portal") })
  const formattedPortalRoutes = portalRoutes.map(route => {
		try {
			if(route.path.includes("portal")){
				return {
				  path: route.path,
				  name: route.name,
				  component: () => import(`@/views/clinicadmin/portal/Home`),
				  meta: route.meta_info,
				}

			} 
		} catch (error) {
			console.error(`Failed to import component for route: ${route.name}`, error);
			return null;
		}
	});
  let surveyRoutes = routes.filter(route => { return route.path.includes("survey") })
	const formattedSurveyRoutes = surveyRoutes.map(route => {
		try {
			if (route.path.includes("survey")) {
				return {
				  path: route.path,
				  name: route.name,
				  component: () => import(`@/views/clinicadmin/survey/Home`),
				  meta: route.meta_info,
				}

			} 
		} catch (error) {
			console.error(`Failed to import component for route: ${route.name}`, error);
			return null;
		}
	});

	const parentPortalRoute = router.options.routes.find(route => route.name === 'DynamicPortal');
  if (parentPortalRoute) {
    parentPortalRoute.children = [...(parentPortalRoute.children || []), ...formattedPortalRoutes];
  }
  const parentSurveyRoute = router.options.routes.find(route => route.name === 'DynamicSurvey');
  if (parentSurveyRoute) {
    parentSurveyRoute.children = [...(parentSurveyRoute.children || []), ...formattedSurveyRoutes];
  }

  router.options.routes.forEach(route => {
  	router.addRoute(route);
  })
}

initializeRoutes().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});