import session from '@/shared/session'

const state = {
	permissions: {},
	roles: [],
}

// getters
const getters = {
	getPermissions: (state) => () => {
		return state.permissions
	},
	getRoles: (state) => () => {
		return state.roles
	}
}

// actions
const actions = {
	setUser: ({commit, state}, user) => {
		commit('set_user', user)
		return state.current
	},

	getAllClinicPatientData ({ commit, dispatch }) {
		dispatch("loading/load", "grafs", { root: true })
		return session
			.get('/api/main/grafs/clinic-patients/')
            .then(response => {
				const results = response.data
				return results
			})
			.finally(() => {
				dispatch("loading/end", "grafs", { root: true })
			})
	},
	getClinicPatientByIdData ({ commit, dispatch }, data) {
		dispatch("loading/load", "grafs", { root: true })
		return session
			.get('/api/main/grafs/clinic-patients/', {
				params: data,
			})
            .then(response => {
				const results = response.data
				return results
			})
			.finally(() => {
				dispatch("loading/end", "grafs", { root: true })
			})
	},
	getClinicScoresByIdData ({ commit, dispatch }, data) {
		dispatch("loading/load", "grafs", { root: true })
		return session
			.get('/api/main/grafs/patient-scores/', {
				params: data,
			})
            .then(response => {
				const results = response.data
				return results
			})
			.finally(() => {
				dispatch("loading/end", "grafs", { root: true })
			})
	},
}

// mutations
const mutations = {
	set_permissions(state, permissions) {
		state.permissions = permissions
	},
	set_roles(state, roles) {
		state.roles = roles
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}